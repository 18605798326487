
import { initializeTranslations } from './translationService';
import { supabase } from "@/integrations/supabase/client";
import { ALL_TRANSLATION_SECTIONS } from '../components/TranslationProvider';

export const setupTranslations = async () => {
  try {
    // Get preferred language from localStorage or browser settings
    const savedLanguage = localStorage.getItem('preferredLanguage');
    const browserLang = navigator.language.split('-')[0];
    const preferredLang = savedLanguage || browserLang || 'en';

    console.log("Setting up translations system...");
    console.log("Preferred language:", preferredLang);
    
    // Check if we have languages
    const { error: languageError, count: langCount } = await supabase
      .from('languages')
      .select('*', { count: 'exact', head: true });

    console.log("Language count check result:", langCount, languageError ? languageError.message : "No error");

    // If no languages or error, initialize them
    if (languageError || (langCount !== null && langCount < 2)) {
      console.log("No languages found in database. Initializing languages...");
      try {
        // Use the project URL instead of accessing protected properties
        const projectUrl = "https://dwxhsyqcytpjmnoohtpj.supabase.co";
        const url = `${projectUrl}/functions/v1/init-languages`;
        
        console.log("Calling init-languages function at:", url);
        const response = await fetch(url, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${process.env.SUPABASE_ANON_KEY || 'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpc3MiOiJzdXBhYmFzZSIsInJlZiI6ImR3eGhzeXFjeXRwam1ub29odHBqIiwicm9sZSI6ImFub24iLCJpYXQiOjE3NDAwMjgwOTIsImV4cCI6MjA1NTYwNDA5Mn0.4vYK8WlmrQnL9OIu26QnXlw_eagQFICjPAOsPbm12M4'}`
          }
        });
        
        const result = await response.text();
        console.log('Languages initialization response:', response.status, result);
        
        if (!response.ok) {
          throw new Error(`Failed to initialize languages: ${response.statusText}`);
        }
        
        console.log('Languages initialized successfully');
        // Force a delay to ensure languages are written to DB
        await new Promise(resolve => setTimeout(resolve, 2000));
      } catch (err) {
        console.error("Failed to initialize languages:", err);
        console.error("Failed to load languages. Using English as fallback.");
      }
    }

    // Check if we have any translations
    const { error: translationError, count: translationCount } = await supabase
      .from('translations')
      .select('*', { count: 'exact', head: true });

    console.log("Translation count check result:", translationCount, translationError ? translationError.message : "No error");

    // If no translations or error, seed them
    if (translationError || (translationCount !== null && translationCount < 10)) {
      console.log("Insufficient translations found in database. Seeding translations...");
      try {
        // Use the project URL instead of accessing protected properties
        const projectUrl = "https://dwxhsyqcytpjmnoohtpj.supabase.co";
        const url = `${projectUrl}/functions/v1/seed-translations`;
        
        console.log("Calling seed-translations function at:", url);
        const response = await fetch(url, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${process.env.SUPABASE_ANON_KEY || 'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpc3MiOiJzdXBhYmFzZSIsInJlZiI6ImR3eGhzeXFjeXRwam1ub29odHBqIiwicm9sZSI6ImFub24iLCJpYXQiOjE3NDAwMjgwOTIsImV4cCI6MjA1NTYwNDA5Mn0.4vYK8WlmrQnL9OIu26QnXlw_eagQFICjPAOsPbm12M4'}`
          },
          body: JSON.stringify({
            // Include all sections that need translations
            sections: ALL_TRANSLATION_SECTIONS,
            forceUpdate: true // Force update to ensure all translations are complete
          })
        });
        
        const result = await response.text();
        console.log('Translations seeding response:', response.status, result);
        
        if (!response.ok) {
          throw new Error(`Failed to seed translations: ${response.statusText}`);
        }
        
        console.log('Translations seeded successfully');
        // Force a delay to ensure translations are written to DB
        await new Promise(resolve => setTimeout(resolve, 2000));
      } catch (err) {
        console.error("Failed to seed translations:", err);
        console.error("Failed to load translations. Using defaults.");
      }
    }

    // Wait a moment to ensure DB operations have completed
    await new Promise(resolve => setTimeout(resolve, 2000));
    
    // Initialize translations
    await initializeTranslations(preferredLang);
    
    console.log('Translation system initialized successfully');
    return true;
  } catch (error) {
    console.error('Failed to set up translations:', error);
    console.error("Translation system initialization failed. Using fallbacks.");
    return false;
  }
};
